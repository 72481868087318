import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

// 获取原型对象上的push函数，避免路由跳转报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const errors = [
  {
    path: '/error', children: [
      { path: '404', name: 'Page404', component: () => import('../views/error/Page404.vue') },
    ],
  },
  { path: '*', redirect: '/error/404' }
]

const routes = [
  { path: '/', redirect: '/login' },
  { path: '/home', name: 'Home', component: Home },
  { path: '/login', name: 'Login', component: () => import(/* webpackChunkName: "login" */ '../views/login') },
  { path: '/introduce', name: 'PlatformIntroduce', component: () => import('../views/PlatformIntroduce') },
  { path: '/pages', redirect: '/pages/overview',
    component: () => import('../views/layout'),
    children: [
      { path: 'overview', name: 'Overview', component: () => import('../views/Overview.vue') },
      { path: 'projectManagement', name: 'ProjectManagement', component: () => import('../views/ProjectManagement.vue') },
      { path: 'userManagement', name: 'UserManagement', component: () => import('../views/UserManagement.vue') },
      { path: 'introduce', name: 'Introduce', component: () => import('../views/Introduce.vue') },
      { path: 'production-situation', name: 'ProductionSituation', component: () => import('../views/productionSituation') },
      // 监控视频
      { path: 'video', name: 'Video', component: () => import('../views/productionSituation/video.vue') },
      { path: 'production-total', name: 'ProductionTotal', component: () => import('../views/productionTotal') },
      { path: 'userinfo', name: 'UserInfo', component: () => import('../views/UserInfo') },
      {
        path: 'device-management',
        name: 'DeviceManagement',
        redirect: '/pages/device-management/deviceManagement-paramList',
        component: () => import('../views/deviceManagement/index.vue'),
        children: [
          {
            path: 'deviceManagement-paramList', name: 'ParamList',
            meta: { activeMenu: 'DeviceManagement' },
            component: () => import('../views/deviceManagement/ParamList.vue'),
          },
          {
            path: 'deviceManagement-historyRecord', name: 'HistoryRecord',
            meta: { activeMenu: 'DeviceManagement' },
            component: () => import('../views/deviceManagement/HistoryRecord.vue'),
          },
          {
            path: 'deviceManagement-upKeeps', name: 'UpKeeps',
            meta: { activeMenu: 'DeviceManagement' },
            component: () => import('../views/deviceManagement/UpKeep.vue'),
          },
        ],
      },
      {
        path: 'homework-diary',
        name: 'HomeworkDiary',
        // redirect: '/pages/homework-diary/alarm-record',
        component: () => import('../views/homeworkDiary/index.vue'),
        children: [
          {
            path: 'alarm-record', name: 'AlarmRecord',
            meta: { activeMenu: 'HomeworkDiary' },
            component: () => import('../views/homeworkDiary/AlarmRecord.vue'),
          },
          {
            path: 'weibao-record', name: 'WeibaoRecord',
            meta: { activeMenu: 'HomeworkDiary' },
            component: () => import('../views/homeworkDiary/WeibaoRecord.vue'),
          },
          {
            path: 'yuanliao-record', name: 'YuanliaoRecord',
            meta: { activeMenu: 'HomeworkDiary' },
            component: () => import('../views/homeworkDiary/YuanliaoRecord.vue'),
          },
          {
            path: 'qiting-record', name: 'QitingRecord',
            meta: { activeMenu: 'HomeworkDiary' },
            component: () => import('../views/homeworkDiary/QitingRecord.vue'),
          },
          {
            path: 'other-record', name: 'OtherRecord',
            meta: { activeMenu: 'HomeworkDiary' },
            component: () => import('../views/homeworkDiary/OtherRecord.vue'),
          },
        ],
      },
      { path: 'history-data', name: 'HistoryData', component: () => import('../views/historyData') },
      {
        path: 'system-log', name: 'SystemLog',
        meta: { activeMenu: 'SystemLog' },
        component: () => import('../views/otherContent/SystemLog.vue'),
      },
      {
        path: 'role-management', name: 'RoleManagement',
        meta: { activeMenu: 'RoleManagement' },
        component: () => import('../views/otherContent/RoleManagement.vue'),
      },
      // {
      //   path: 'other-content',
      //   name: 'OtherContent',
      //   // redirect: '/pages/other-content/role-management',
      //   component: () => import('../views/otherContent/index.vue'),
      //   children: [
      //     {
      //       path: 'role-management', name: 'RoleManagement',
      //       meta: { activeMenu: 'OtherContent' },
      //       component: () => import('../views/otherContent/RoleManagement.vue'),
      //     },
      //     {
      //       path: 'system-log', name: 'SystemLog',
      //       meta: { activeMenu: 'OtherContent' },
      //       component: () => import('../views/otherContent/SystemLog.vue'),
      //     }
      //   ],
      // },
    ]
  },
  ...errors
]

if(process.env.NODE_ENV !== 'production') {
  routes.push({
    path: '/style',
    name: 'Style',
    component: () => import(/* webpackChunkName: "style" */ '../views/style'),
  })
}

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
