import interceptorConfig from './config'
import { getToken, setToken } from '@/utils/auth'

export const interceptor = {
  /**
   * 身份验证
   * @param to
   * @returns {{toPath: string, state: boolean}}
   */
  auth(to) {
    let config = interceptorConfig.default, state = true, toPath
    for(const key in interceptorConfig) {
      if(new RegExp(key.replace(/\*/g, '.*')).test(to.path)) {
        config = interceptorConfig[key]; break
      }
    }
    if(to.query.token) setToken(to.query.token)
    const token = getToken()
    // 如果页面需要token且没有
    if(config.token && !token) {
      state = false
      toPath = config.notTokenTo
    } else if(token && config.tokenTo) { // 如果页面不需要token且有token和需要跳转的地址
      state = false
      toPath = config.tokenTo
    }
    return { state, toPath }
  }
}
