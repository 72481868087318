import Vue from 'vue'
import vueScroll from 'vuescroll'
Vue.use(vueScroll, {
  ops: {
    bar: {
      background: '#147AFF'
    }
  },
  name: 'el-scrollbar'
})
