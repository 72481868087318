import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userInfo: undefined,
    currentProject: undefined,
    menuDictionary: undefined,
    menuList: undefined
  },
  mutations: {
    SET_USER_INFO: (state, val) => {
      state.userInfo = val
    },
    SET_CURRENT_PROJECT: (state, val) => {
      state.currentProject = val
    },
    SET_MENU_DICTIONARY: (state, val) => {
      state.menuDictionary = val
    },
    SET_MENU_LIST: (state, val) => {
      state.menuList = val
    }
  },
  actions: {
    /**
     * 设置用户信息
     * @param commit
     * @param val
     */
    setUserInfo({ commit }, val) {
      commit('SET_USER_INFO', val)
      sessionStorage.setItem('sui', JSON.stringify(val))
    },
    setCurrentProject({ commit }, val) {
      commit('SET_CURRENT_PROJECT', val)
      sessionStorage.setItem('scp', JSON.stringify(val))
    },
    setMenuDictionary({ commit }, val) {
      commit('SET_MENU_DICTIONARY', val)
      sessionStorage.setItem('smd', JSON.stringify(val))
    },
    setMenuList({ commit }, val) {
      commit('SET_MENU_LIST', val)
      sessionStorage.setItem('sml', JSON.stringify(val))
    },
  },
  getters: {
    userInfo: state => {
      if(state.userInfo === undefined) {
        state.userInfo = JSON.parse(sessionStorage.getItem('sui'))
      }
      return state.userInfo
    },
    currentProject: state => {
      if(state.currentProject === undefined) {
        state.currentProject = JSON.parse(sessionStorage.getItem('scp'))
      }
      return state.currentProject
    },
    menuDictionary: state => {
      if(state.menuDictionary === undefined) {
        state.menuDictionary = JSON.parse(sessionStorage.getItem('smd'))
      }
      return state.menuDictionary || {}
    },
    menuList: state => {
      if(state.menuList === undefined) {
        state.menuList = JSON.parse(sessionStorage.getItem('sml'))
      }
      return state.menuList || [[], [], []]
    }
  },
  modules: {
  }
})
