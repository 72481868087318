<template>
  <div>
    <el-empty v-if="emptyIsShow" :image-size="imageSize" :description="descriptionTip">
      <div slot="image" class="empty-box__image">
        <div class="empty-box__code" v-if="code" :style="{ fontSize: (imageSize / 3) + 'px' }" >{{ code }}</div>
        <el-image :src="imageUrl"></el-image>
      </div>
      <slot name="description" slot="description"></slot>
      <slot name="footer" slot="footer"></slot>
    </el-empty>
    <div :hidden="emptyIsShow">
      <slot></slot>
    </div>
  </div>
</template>

<script>
/**
 * @version 1.0
 * @author MRX
 * --- Attributes ---
 * @param state : Boolean|String|Number|Object|Error 空组件的显示状态
 * Boolean: true 正常显示, false 显示无数据提示
 * String: empty|empty-chart|timeout|other 显示不同的状态
 * Number: 400|404|500... 所有错误码
 * Object: { [type: String], [description: String], [image: String] }
 * --type: empty|empty-chart|timeout|other 显示不同的状态, 默认为empty
 * --description: 提示信息
 * --image: 图片地址
 * Error: axios请求中catch的回调参数,根据错误信息给出相应的错误提示
 * @param imageSize : Number 图片大小 - 对应el-empty的属性
 * --- Slots ---
 * @slot default 需要进行状态显示的元素
 * @slot description 自定义描述文字 - 继承el-empty的description插槽
 * @slot footer 自定义底部内容 - 继承el-empty的default插槽
 */
export default {
  name: 'EmptyBox',
  props: {
    state: null,
    imageSize: {
      type: Number,
      default: 180
    }
  },
  watch: {
    state(nv) {
      this.updateState(nv)
    }
  },
  data() {
    return {
      // 当前状态码值
      code: null,
      // 是否显示空状态
      emptyIsShow: false,
      // 图片地址
      imageUrl: '',
      // 提示信息
      descriptionTip: '暂无数据',
      // 状态字典
      dictionary: {
        empty: { url: require('./images/empty.png'), description: '暂无数据' },
        'empty-chart': { url: require('./images/empty-chart.png'), description: '暂无数据' },
        timeout: { url: require('./images/other.png'), description: '连接超时！' },
        other: { url: require('./images/other.png'), description: '哎呀，出错啦~' },
        code: { url: require('./images/code.png'), description: ' ' }
      },
      // 状态码字典
      dictionaryCode: {
        400: '请求参数有误',
        404: '迷路了，找不到您访问的资源~',
        500: '抱歉，服务器出错了~',
        403: '抱歉，您无权访问此资源~'
      }
    }
  },
  mounted() {
    this.updateState(this.state)
  },
  methods: {
    /**
     * 验证是否是一个普通对象
     * @param _
     * @returns {boolean}
     */
    isPlainObj: (_) => Object.prototype.toString.call(_) === '[object Object]',

    /**
     * 更新当前显示状态
     * @param state
     */
    updateState(state) {
      if(state === true) {
        this.emptyIsShow = false
        return
      }
      this.code = false
      let type = 'empty', description = '', image = ''
      // 验证参数类型,处理各参数信息,提取type,description等信息
      // 如果是string或number类型的参数
      if(typeof state === 'string' || typeof state === 'number') {
        type = state
      } else if(this.isPlainObj(state)) { // 如果是普通object对象
        state?.type && (type = state.type)
        state?.image && (image = state.image)
        state?.description && (description = state.description)
      } else if(state !== false) { // (false为默认)如果是请求的错误参数
        // 验证是否是请求超时
        if(state.toString().indexOf('timeout') > -1) {
          type = 'timeout'
        } else {
          // 否则提取错误码
          const code = state.toString().replace(/^.*[code] (.*)$/, '$1')
          if(!isNaN(Number(code))) {
            type = 'code'
            this.code = Number(code)
          }
        }
      }

      // 判断type是否是以错误码显示
      if(!isNaN(Number(type))) {
        this.code = type
        type = 'code'
      }
      if(type === 'code') {
        description = description || this.dictionaryCode[this.code] || ''
      }
      // 如果在字典中找不到对应的状态,则使用other
      // eslint-disable-next-line one-var
      let obj = this.dictionary[type]
      !obj && (obj = this.dictionary.other)

      this.imageUrl = image || obj.url
      this.descriptionTip = description || obj.description
      this.emptyIsShow = true
    }
  }
}
</script>

<style lang="scss" scoped>
.empty-box__image{
  opacity: .9;
  filter: hue-rotate(215deg)
}
.empty-box__code{
  color: #ecd2d0;
  font-size: 48px;
  text-indent: .3em;
  letter-spacing: .2em;
  font-weight: bold;
  position: absolute;
  left: 0;right: 0;
  top: 30%;bottom: 0;
  margin:auto;
  text-shadow: 4px 0 #cb8d86;
  z-index: 4;
}
</style>
