<template>
  <div class="home p-3">
    <a href="#/style">
      <el-button type="primary">主题预览</el-button>
    </a>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {
      uId: ''
    }
  },
  components: {
  },
  mounted() {
  },
  methods: {}
}
</script>
