import instance from './request'

/**
 * 获取 Token
 */
export function getToken() {
  return sessionStorage.getItem('ACCESS_TOKEN')
}

/**
 * 设置 Token
 * @param token
 */
export function setToken(token) {
  instance.defaults.headers.common['token'] = token
  sessionStorage.setItem('ACCESS_TOKEN', token)
}

/**
 * 清除 Token
 */
export function removeToken() {
  sessionStorage.clear()
  delete instance.defaults.headers.common['token']
}
