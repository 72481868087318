import axios from 'axios'
// import qs from 'qs'
import router from '../router'
import { Message } from 'element-ui'
import { getToken, removeToken } from './auth'
// const pending = {} // 网络请求记录
// const CancelToken = axios.CancelToken
const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 120000,
  // transformRequest: [function(data, headers) {
  //   return qs.stringify(data, { arrayFormat: 'repeat' }, { indices: false })
  // }]
})

instance.defaults.headers.common['token'] = getToken()

// request interceptor
// instance.interceptors.request.use(
//   config => {
//     if(config.headers['notCancel'] === true) {
//       delete config.headers['notCancel']; return config
//     }
//     // 判断请求地址,不拦截非'/'开头的请求,如其他服务器地址'http://www.xxx.com/'
//     if(config.url.substr(0, 1) !== '/') return config
//     const key = `${config.url}&${config.method}&${JSON.stringify(config.data)}`
//     // 设置重复请求
//     config.cancelToken = new CancelToken(c => {
//       if(pending[key]) {
//         if(Date.now() - pending[key].time > 1000) {
//           // 超过1s，删除对应的请求记录，重新发起请求
//           delete pending[key]
//         } else {
//           // 1s以内的已发起请求，取消重复请求
//           c('Cancelled your repeated request')
//           if(++pending[key].count >= 5) {
//             Message.warning('您的操作过快，请不要频繁操作')
//             pending[key].count = 0
//           }
//           // 更新时间戳
//           pending[key].time = Date.now()
//         }
//       }
//       if(!pending[key]) {
//         // 记录当前的请求
//         pending[key] = { time: Date.now(), count: 0 } // time 最近触发时间，count 拦截次数
//       }
//     })
//     return config
//   },
//   error => {
//     return Promise.reject(error)
//   }
// )

// response interceptor
instance.interceptors.response.use(
  response => {
    if(response.data.code === 532) {
      if(getToken()) {
        removeToken()
        Message.warning(response.data.message || '无登录信息或已失效')
      }
      router.push({ name: 'Login' })
    }
    response.success = (callback) => {
      switch (response.data.code) {
        case 200:
          callback(response.data); break
        default:
          Message.warning(response.data.message); break
      }
    }
    return response
  },
  error => {
    if(error.toString().indexOf('timeout') > -1) {
      Message.warning('请求超时，请稍后重试')
    } else {
      const code = error.toString().replace(/^.*[code] (.*)$/, '$1')
      switch (Number(code)) {
        case 400:
          Message.warning('请求参数有误，400'); break
        case 404:
          Message.warning('您访问的资源不存在，404'); break
        case 405:
          Message.warning('请求方法不能被用于请求相应的资源，405'); break
        case 415:
          Message.warning('请求不支持该媒体类型，415'); break
        case 500:
          Message.error('请求服务器错误'); break
        case 403:
          Message.warning('服务器拒绝了您的请求，403'); break
        default:
          if(code > 500) {
            Message.error('请求服务器出现异常，' + code)
          }
      }
    }
    return Promise.reject(error)
  }
)

export default instance
