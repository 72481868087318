import router from '@/router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { settings } from '@/settings'
import { interceptor } from '@/router/interceptor'

// 添加路由前置守卫
router.beforeEach(async(to, from, next) => {
  NProgress['start']()
  window.loadingBox.show()
  // 身份验证
  const authResult = interceptor.auth(to)
  if(authResult.state) {
    const { title } = to.meta
    document.title = `${title ? title + ' - ' : ''}${settings.title}`
    next()
  } else {
    window.loadingBox.hide()
    NProgress['done']()
    next(authResult.toPath)
  }
})

// 添加路由后置守卫
router.afterEach((to, from) => {
  window.loadingBox.hide()
  NProgress['done']()
})
