import Vue from 'vue'
import EmptyBox from '@/components/EmptyBox'
import { Button, Card, Image, Avatar, Empty, Link } from 'element-ui'

Vue.use(Button)
Vue.use(Card)
Vue.use(Image)
Vue.use(Avatar)
Vue.use(Empty)
Vue.use(Link)
Vue.use(EmptyBox)
