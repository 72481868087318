/**
 * @version 1.1
 * @author MRX
 */
const isArray = (_) => Array.isArray(_)
const isPlainObj = (_) => Object.prototype.toString.call(_) === '[object Object]'
// const isUndef = (_) => typeof _ === 'undefined'

/**
 * 根据地址获取源数据中的值
 * 如source = {a: {b: 1}}，address = 'a.b'，得到 1
 * address = 'a.b.c'，得到 undefined
 * @param source Object 数据源
 * @param address String 数据地址
 * @return {undefined|*} 返回获取到的值
 */
const getAttr = (source, address) => {
  const tier = address.split('.')
  try {
    tier.forEach(key => {
      source = source[key]
    })
    return source
  } catch (e) {
    return undefined
  }
}

/**
 * assignDeep(target, source);
 * 合并对象
 * @param {Object} target
 * @param {Object} source
 * @param {Boolean} deep : Boolean 当deep为false时target不接收新的属性
 * @return {Object}
 */
const assignDeep = (target, source, deep = true) => {
  for(const key in source) {
    const val = source[key]
    // 如果两个值都是object类型或都是array类型
    if((isArray(val) && isArray(target[key])) || (isPlainObj(val) && isPlainObj(target[key]))) {
      assignDeep(target[key], val, deep)
    } else {
      if(deep || target[key] !== undefined) {
        target[key] = val
      }
    }
  }
  return target
}

/**
 * 深拷贝 - 递归进行
 * @param from
 * @param way : String ; params: json; default: json;
 * @returns {*|{}}
 */
const deepCopy = (from, way) => {
  if(way === 'json') {
    return JSON.parse(JSON.stringify(from))
  } else {
    return __deepCopy(from)
  }
}
function __deepCopy(from, to) {
  if(isArray(from)) {
    to = []
    from.forEach((item, index) => {
      to[index] = __deepCopy(item, to[index])
    })
  } else if(from) {
    if(!isPlainObj(from)) {
      return from
    }
    to = {}
    for(const key in from) {
      to[key] = typeof from[key] === 'object' ? __deepCopy(from[key], to[key]) : from[key]
    }
  }
  return to
}

/**
 * 判断是否为空
 * @param value
 * @param defaultVal
 * @return {boolean}
 */
const notEmpty = (value, defaultVal) => {
  let empty = false, notEmpty = true
  if(defaultVal !== undefined) {
    empty = defaultVal
    notEmpty = value
  }
  return value ? notEmpty : value === 0 ? notEmpty : empty
}

/**
 * 保留位数
 * @param fractionDigits
 * @returns {number}
 */
const toFixedNum = function(fractionDigits) {
  return Number(this.toFixed(fractionDigits))
}

Object.defineProperty(Object.prototype, 'getAttr', { enumerable: false, value: getAttr })
Object.defineProperty(Object.prototype, 'assignDeep', { enumerable: false, value: assignDeep })
Object.defineProperty(Object.prototype, 'deepCopy', { enumerable: false, value: deepCopy })
Object.defineProperty(Object.prototype, 'notEmpty', { enumerable: false, value: notEmpty })
Object.defineProperty(Number.prototype, 'toFixedNum', { enumerable: false, value: toFixedNum })
