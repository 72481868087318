import Vue from 'vue'
import { Input, Checkbox, Radio, InputNumber, Select, Option, Cascader,
  DatePicker, TimePicker, Upload, ColorPicker, Form, FormItem, RadioGroup, RadioButton, Autocomplete, Transfer } from 'element-ui'

Vue.use(Input)
Vue.use(Checkbox)
Vue.use(Radio)
Vue.use(InputNumber)
Vue.use(Select)
Vue.use(Option)
Vue.use(Cascader)
Vue.use(DatePicker)
Vue.use(TimePicker)
Vue.use(Upload)
Vue.use(ColorPicker)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(RadioGroup)
Vue.use(RadioButton)
Vue.use(Autocomplete)
Vue.use(Transfer)
