import Vue from 'vue'
import { Row, Col, Collapse, CollapseItem, Dialog, Popover, Descriptions, DescriptionsItem } from 'element-ui'

Vue.use(Row)
Vue.use(Col)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Dialog)
Vue.use(Popover)
Vue.use(Descriptions)
Vue.use(DescriptionsItem)
